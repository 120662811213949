import { useState, useRef } from 'react';

import { useNavigate, Route, Routes } from 'react-router-dom';

import { Menu as MenuIcon, AccountBox as AccountBoxIcon } from '@mui/icons-material';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, styled } from '@mui/material';

import { useMedia } from '../lib/media';
import { useLogout } from '../lib/user/hooks';
import useUser from '../lib/user/useUser';

import AccountEdit from './cms/account/AccountEdit';
import NavigationDrawer from './cms/NavigationDrawer';
import CMSRouteSections from './cms/route-sections';

const Container = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled('div')({
  flex: 1,
  display: 'flex',
});

const StyledNavigationDrawer = styled(NavigationDrawer)({
  width: '200px',
  flexShrink: 0,

  '.MuiDrawer-paper': {
    width: '200px',
    boxSizing: 'border-box',
    zIndex: 0,
  },
});

const StyledMain = styled('main')({
  height: '100%',
  flexGrow: 1,
  backgroundColor: '#eee',
  display: 'flex',
  flexDirection: 'column',
});

const CmsContainer = () => {
  const navigate = useNavigate();
  const mediaBreakpoint = useMedia();
  const [open, setOpen] = useState(false);
  const { mutate: doLogout } = useLogout({
    onSuccess: () => {
      setUser(null);
      navigate('/login');
    },
  });
  const [user, setUser] = useUser();
  const [menu, setMenu] = useState(false);
  const menuButton = useRef(null);

  const handleOpenMenu = () => setMenu(true);
  const handleCloseMenu = () => setMenu(false);

  const editAccount = () => {
    if (!user) {
      return;
    }
    navigate(`/cms/account/${user.id}`);
  };

  const logout = () => doLogout();

  return (
    <Container>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{ mr: 2, ...(!mediaBreakpoint.sm && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            CMS
          </Typography>
          <IconButton
            color="inherit"
            ref={menuButton}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpenMenu}
          >
            <AccountBoxIcon />
          </IconButton>
          <Menu variant="selectedMenu" open={menu} anchorEl={menuButton.current} onClick={handleCloseMenu} onClose={handleCloseMenu}>
            <MenuItem onClick={editAccount}>Account</MenuItem>
            <MenuItem onClick={logout}>Log Out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Content>
        <StyledNavigationDrawer
          open={open}
          onClose={() => setOpen(false)}
          sections={CMSRouteSections}
          variant={mediaBreakpoint.sm ? 'temporary' : 'permanent'}
          anchor="left"
        />
        <StyledMain>
          <Toolbar />
          <Routes>
            {CMSRouteSections.map((section) => (
              <Route key={section.path} path={`${section.path}/*`} Component={section.component} />
            ))}
            <Route path="/account/:id" Component={AccountEdit} />
          </Routes>
        </StyledMain>
      </Content>
    </Container>
  );
};

export default CmsContainer;
