import { useMutation, useQueryClient } from 'react-query';

import Api from '../../api/api';

import { RolesDTO } from './useGetAllRoles';

export type UserUpdateDTO = {
  username: string;
  email: string;
  currentPassword?: string;
  newPassword?: string;
  roles?: RolesDTO[];
};

export const updateUser = async (userId: string, params: UserUpdateDTO) => {
  const [response, bodyOrError] = await Api.patch(`users/${userId}`, params);

  if (!response.ok) {
    throw bodyOrError;
  }

  return response;
};

export const useUpdateUser = (userId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    'updateUser',
    (params: UserUpdateDTO) => {
      return updateUser(userId, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getUser', userId]);
      }
    }
  );
};
