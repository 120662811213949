import { useState } from 'react';

import { Formik } from 'formik';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import { Grid, Card, styled } from '@mui/material';

import { SubmitButton } from '../../components/buttons';
import { FormContainer } from '../../components/forms';
import { TextField } from '../../components/inputs';
import { ErrorMessages } from '../../constants';
import { LogInDTO, useLogin } from '../../lib/user/hooks/useLogin';
import useUser from '../../lib/user/useUser';
import { User } from '../../types/User';

const LogInSchema = Yup.object().shape({
  email: Yup.string().email(ErrorMessages.INVALID_EMAIL).required(ErrorMessages.REQUIRED_EMAIL),
  password: Yup.string().required(ErrorMessages.REQUIRED_PASSWORD),
});

const Container = styled('div')({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#eee',
});

const StyledCard = styled(Card)({
  width: '400px',
});

const BottomContainer = styled('div')({
  marginTop: '20px',
  alignSelf: 'stretch',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
});

const ErrorContainer = styled('div')({
  flex: 1,
  color: 'red',
});

type Role = {
  id: number;
  name: string;
};

const LogIn = () => {
  const [, setUser] = useUser();
  const onSuccess = (user: User) => {
    if (user.roles && user.roles.find((r: Role) => r.name === 'Admin')) {
      setUser(user);
      navigate('/');
      return;
    }
    setLoginError(new Error('You do not have sufficient permissions.'));
  };
  const onError = (error: Error) => setLoginError(error);
  const { error, mutate: doLogin, isLoading } = useLogin({ onSuccess, onError });

  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(error);

  const handleSubmit = (values: LogInDTO) => doLogin(values);
  const setInitValue = (value?: string | boolean) => value || ' ';

  return (
    <Container>
      <StyledCard>
        <Formik initialValues={{ email: '', password: '' }} validationSchema={LogInSchema} validateOnBlur={true} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <FormContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={touched.email && !!errors.email}
                    type="email"
                    name="email"
                    label="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    helperText={setInitValue(touched.email && errors.email)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={touched.password && !!errors.password}
                    type="password"
                    name="password"
                    label="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    helperText={setInitValue(touched.password && errors.password)}
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
              <BottomContainer>
                <ErrorContainer>{loginError && loginError.message}</ErrorContainer>
                <SubmitButton type="submit" variant="contained" disabled={isLoading} loading={isLoading}>
                  Log In
                </SubmitButton>
              </BottomContainer>
            </FormContainer>
          )}
        </Formik>
      </StyledCard>
    </Container>
  );
};

export default LogIn;
