import { useState } from "react";

import { Checkbox, Grid, OutlinedInput, Slider, Typography, styled } from "@mui/material";

import { ChatCompletionSettings } from "../../../types/ChatCompletionSettings";
import { ChatControlConfiguration } from "../../../types/ChatControlConfiguration";


const NumberInput = styled('input')({
  textAlign: 'right',
});

interface ChatSettingControlProps {
  config?: ChatControlConfiguration;
  name: keyof ChatCompletionSettings;
  value: any;
  onChange(value?: number | boolean): void;
}

const ChatSettingControl: React.FC<ChatSettingControlProps> = (props) => {
  const { config, name, value, onChange } = props;
  const [enabled, setEnabled] = useState(!config?.optional && value != null)

  const enableControl = (enabled: boolean) => {
    setEnabled(enabled)
    if (!enabled) {
      onChange(undefined);
    } else {
      onChange(0);
    }
  }

  return config && (
    config.type === 'flag'
      ? <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
          <Typography variant="body1">{config.label}:</Typography>
          <Checkbox
            name={name}
            checked={!!value}
            onChange={(e) => onChange(e.target.checked)}
          />
        </Grid>
      : <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
          <Typography variant="body1">{config.label}:</Typography>
          <span>
            {config.optional &&
              <Checkbox
                checked={enabled}
                onChange={(e) => enableControl(e.target.checked)}
              />
            }
            <OutlinedInput
              type="number"
              name={name}
              value={value || 0}
              inputComponent={NumberInput}
              disabled={!enabled}
              inputProps={{ 
                min: config.minValue,
                max: config.maxValue,
                step: config.step || 1,
              }}
              onChange={(e) => onChange(+(e.target.value))}
            />
          </span>
          {config.minValue != null && config.maxValue != null &&
            <Grid item xs={12} sx={{ paddingLeft: '10px', paddingRight: '10px'}}>
              <Slider
                name={name}
                min={config.minValue}
                max={config.maxValue}
                step={config.step || 1}
                value={+value}
                onChange={(_e, value) => onChange(+value)}
              />
            </Grid>
          }
        </Grid>
  );
};
  
export default ChatSettingControl;