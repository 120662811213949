import { useMemo } from "react";

import Cookies from "js-cookie";
import OpenAI, { ClientOptions } from "openai";

import { Defaults } from "../../constants";
import { HttpStatusCode } from "../api/api";
import { refreshToken } from "../api/TokenRefresher";

const API_URL = process.env.REACT_APP_BASE_URL || `http://localhost:3003/api/`;

export default function useOpenAI(options?: ClientOptions): OpenAI {
  const factory = () => new OpenAI({
    apiKey: '',
    baseURL: API_URL,
    ...options,
    dangerouslyAllowBrowser: true,
    fetch: async(url: RequestInfo, init?: RequestInit): Promise<Response> => {
      const requestOptions = {...init} || {};
      let useAccessToken = false;
      if (!options?.apiKey) {
        requestOptions.headers = {
          ...requestOptions.headers,
          authorization: 'Bearer ' + Cookies.get(Defaults.ACCESS_TOKEN_COOKIE_KEY),
        }
        useAccessToken = true;
      }
      const response = await fetch(url, requestOptions);
      if (response.status === HttpStatusCode.FORBIDDEN && useAccessToken) {
        const refreshed = await refreshToken();
        if (refreshed) {
          requestOptions.headers = {
            ...requestOptions.headers,
            authorization: 'Bearer ' + Cookies.get(Defaults.ACCESS_TOKEN_COOKIE_KEY),
          }
          return await fetch(url, requestOptions);
        }
      }
      return response;
    }
  });

  return useMemo(factory, [options]);
};