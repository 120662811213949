import { Button, styled } from '@mui/material';

const MAX_WIDTH = 5;

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

type PaginationProps = {
  className?: string;
  current: number;
  total: number;
  onPageChange: (page: number) => void;
};

const StyledButton = styled(Button)({
  minWidth: '30px',
  padding: 0,
  margin: '10px 2px',
});

export const Pagination = (props: PaginationProps) => {
  const { className, current, total, onPageChange } = props;
  const range = generateRange(current, total);
  return (
    <Container className={className}>
      {range.map((r) => (
        <StyledButton key={r} variant={current ? 'outlined' : 'text'} onClick={() => onPageChange(r)}>
          {r}
        </StyledButton>
      ))}
    </Container>
  );
};

const generateRange = (current: number, total: number) => {
  const half = Math.floor(MAX_WIDTH / 2);
  let start = Math.min(Math.max(current - half, 1), Math.max(total - MAX_WIDTH + 1, 1));
  let end = Math.max(Math.min(total, current + half), Math.min(total, MAX_WIDTH));

  const range: number[] = [];
  for (let i = start; i <= end; i++) {
    range.push(i);
  }

  return range;
};
