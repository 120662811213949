import { useMutation } from 'react-query';

import { User } from '../../../types/User';
import Api from '../../api/api';

export const deleteUser = async (userId: string) => {
  const [response, bodyOrError] = await Api.delete(`users/${userId}`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useDeleteUser = (userId: string) => useMutation<User, Error>('deleteUser', () => deleteUser(userId));
