import { Route, Routes } from 'react-router';

import ChatShell from './ChatShell';

function ChatModule() {
  return (
    <Routes>
      <Route path="" element={<ChatShell />} />
    </Routes>
  );
}

export default ChatModule;
