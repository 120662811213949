import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import mammoth from 'mammoth';
import { ChatCompletion, ChatCompletionChunk, ChatCompletionContentPartImage, ChatCompletionContentPartText, ChatCompletionMessageParam } from 'openai/resources';
import { Stream } from 'openai/streaming';
import Markdown, { Components } from 'react-markdown';
import { PluggableList } from 'react-markdown/lib';
import pdfToText from 'react-pdftotext';
import { useParams } from 'react-router-dom';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import remarkGfm from 'remark-gfm';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SendIcon from '@mui/icons-material/Send';
import { Chip, Grid, IconButton, Input, InputBase, Paper, Stack, Typography, styled } from '@mui/material';

import { AlertSnackbarContext } from '../../../components/snackbars';
import { Spinner } from '../../../components/spinners';
import useOpenAI from '../../../lib/chat/useOpenAI';
import { useGetUser } from '../../../lib/user/hooks';
import { ChatCompletionSettings } from '../../../types/ChatCompletionSettings';

const DEFAULT_REMARK_PLUGINS: PluggableList = [remarkGfm];
const DEFAULT_REHYPE_PLUGINS: PluggableList = [];

const fileToBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(`${reader.result}`);
    reader.onerror = reject;
  });
};

const fileToText = async (file: File): Promise<string> => {
  if (file.type === 'application/pdf') {
    return await pdfToText(file);
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const isDocx = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (isDocx) {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file);
    }
    reader.onload = async () => {
      if (isDocx) {
        const { value } = await mammoth.extractRawText({ arrayBuffer: reader.result as ArrayBuffer })
        resolve(value);
      } else {
        resolve(`${reader.result}`);
      }
    }
    reader.onerror = reject;
  });
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const ChatWindow = styled('div')({
  backgroundColor: '#eee',
  position: 'relative',
  flexGrow: 1,
  width: '100%',
  overflowY: 'auto',
});

const ChatContainer = styled('div')({
  position: 'absolute',
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  padding: '40px 24px 24px 24px',
  width: '100%',
})

const MessageBubble = styled('div')({
  border: '1px solid transparent',
  borderRadius: 14,
  padding: '.75rem 1rem',
  marginBottom: '10px',
  width: 'fit-content',
  maxWidth: '80%',
});

const FullWidthBar = styled(Paper)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  bottom: 0,
  paddingTop: 5,
  paddingBottom: 10,
  paddingLeft: 5,
  marginLeft: 20,
  marginRight: 20,
});

const ImageContainer = styled('div')({
  position: 'relative',
  width: 125,
  maxWidth: '30vw',
  aspectRatio: 1,
});

const ImagePreview = styled('img')({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  padding: '15px 15px 0 0',
});

const ImageMessage = styled('img')({
  maxWidth: '100%',
});

type Message = ChatCompletionMessageParam & { 
  id?: string;
  sourceFiles?: File[];
};

interface MarkdownSettings {
  remarkPlugins?: PluggableList; // Overrides defaults
  additionalRemarkPlugins?: PluggableList; // Appends to defaults
  rehypePlugins?: PluggableList; // Overrides defaults
  additionalRehypePlugins?: PluggableList; // Appends to defaults
  components?: Partial<Components>;
}

interface ChatProps extends ChatCompletionSettings {
  model: string;
  markdownSettings?: MarkdownSettings;
  enableImages?: boolean;
};

const Chat: React.FC<ChatProps> = (props) => {
  const { model, markdownSettings, enableImages, ...settings } = props;
  const openai = useOpenAI();

  const { id } = useParams();
  const userId = id ?? '';
  const { isLoading: isUserLoading } = useGetUser(userId);

  const endOfMessages = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState('');
  const [images, setImages] = useState<File[]>([])
  const [attachments, setAttachments] = useState<File[]>([]);
  const [chatLogs, setChatLogs] = useState<Message[]>([]);
  const [chatMemoryThreshold, setChatMemoryThreshold] = useState(0);
  const [, setAlertSnackbar] = useContext(AlertSnackbarContext);

  const flattenedMessageContent = useMemo(() => {
    return chatLogs.flatMap((chat) => {
      const sourceFiles = chat.sourceFiles || [];
      if (Array.isArray(chat.content)) {
        return chat.content.map((content, i) => ({
          isUser: chat.role === 'user',
          type: content.type,
          content: content.type === 'text' ? content.text : content.image_url.url,
          sourceFile: i < sourceFiles.length ? sourceFiles[i]: undefined,
        }));
      } else {
        return {
          isUser: chat.role === 'user',
          type: 'text',
          content: chat.content,
          sourceFile: sourceFiles.length > 0 ? sourceFiles[0]: undefined,
        };
      }
    });
  }, [chatLogs]);

  useEffect(() => {
    endOfMessages.current?.scrollIntoView({ behavior: 'smooth' });
  }, [flattenedMessageContent]);

  const onSelectFile = (input: HTMLInputElement) => {
    if (!input.files) return;
    const newAttachments = [...attachments];
    for (let i = 0; i < input.files.length; i++) {
      const file = input.files.item(i);
      if (file) {
        newAttachments.push(file);
      }
    }
    setAttachments(newAttachments);
    input.value = '';
  };

  const onRemoveFile = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const onSelectImage = (input: HTMLInputElement) => {
    if (!input.files) return;
    const newImages = [...images];
    for (let i = 0; i < input.files.length; i++) {
      const file = input.files.item(i);
      if (file) {
        newImages.push(file);
      }
    }
    setImages(newImages);
    input.value = '';
  };

  const onRemoveImage = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const onEnter = async () => {
    if (message !== '') {
      const updatedMessages: Message[] = [
        ...chatLogs,
        {
          role: 'user',
          content: [
            ...await Promise.all(
              attachments.map<Promise<ChatCompletionContentPartText>>(async (attachment) => (
                {
                  type: 'text',
                  text: await fileToText(attachment),
                }
              ))
            ),
            ...await Promise.all(
              images.map<Promise<ChatCompletionContentPartImage>>(async (image) => (
                {
                  type: 'image_url',
                  image_url: {
                    url: await fileToBase64(image),
                  }
                }
              ))
            ),
            {
              type: 'text',
              text: message,
            },
          ],
          sourceFiles: [...attachments],
        },
      ];
      setChatLogs(updatedMessages);
      postOpenAI(updatedMessages);
      setMessage('');
      setAttachments([]);
      setImages([]);
    }
  };

  const postOpenAI = async (messages: Message[]) => {
    try {
      let response: Stream<ChatCompletionChunk> | ChatCompletion;
      let firstMessageIndex = chatMemoryThreshold;
      while (true) {
        try {
          response = await openai.chat.completions.create({
            ...settings,
            model,
            messages: messages.slice(firstMessageIndex).map((message) => {
              const { id, sourceFiles, ...rest} = message;
              return rest;
            }),
          });
          break;
        } catch (e: any) {
          if (e.status === 413) {
            firstMessageIndex++;
            if (firstMessageIndex > chatLogs.length) {
              throw e;
            }
            continue;
          }
          throw e;
        }
      }
      setChatMemoryThreshold(firstMessageIndex);
      if (!props.stream) {
        // Completion
        const completion = response as ChatCompletion;
        setChatLogs((prev) => [
          ...prev,
          {
            role: 'assistant',
            content: completion.choices[0].message.content || '',
          },
        ]);
      } else {
        // Stream
        for await (const part of response as Stream<ChatCompletionChunk>) {
          const streamMsg = part.choices[0].delta.content;
          if (streamMsg != null)
            setChatLogs((prev) => {
              const updatedLogs = [...prev];
              if (updatedLogs.length > 0 && updatedLogs[updatedLogs.length - 1].id === part.id) {
                updatedLogs[updatedLogs.length - 1] = {
                  id: part.id,
                  role: 'assistant',
                  content: `${updatedLogs[updatedLogs.length - 1].content}` + part.choices[0].delta.content,
                };
              } else
                updatedLogs.push({
                  id: part.id,
                  role: 'assistant',
                  content: part.choices[0].delta.content || '',
                });
  
              return updatedLogs;
            });
        }
      }
    } catch (e) {
      setAlertSnackbar({
        message: `${e}`,
        severity: 'error',
      });
    }
  };

  return (
    <>
      {isUserLoading ? (
        <Spinner />
      ) : (
        <Container>
          <ChatWindow>
            <ChatContainer>
            {flattenedMessageContent &&
              flattenedMessageContent.map((message, i) => (
                <MessageBubble
                  key={i}
                  style={{
                    alignSelf: !message.isUser ? 'flex-start' : 'flex-end',
                    backgroundColor: !message.isUser ? 'rgb(244 244 245)' : 'rgb(59 130 246)',
                    color: !message.isUser ? 'black' : 'rgb(244 244 245)',
                  }}
                >
                  {message.type === 'text'
                    ? message.sourceFile != null
                      ? <Stack direction="column" alignItems="center">
                          <DescriptionIcon fontSize="large" />
                          <Typography variant="subtitle1">{message.sourceFile.name}</Typography>
                        </Stack>
                      : <Markdown
                          children={message.content}
                          remarkPlugins={[
                            ...(markdownSettings?.remarkPlugins || DEFAULT_REMARK_PLUGINS),
                            ...(markdownSettings?.additionalRemarkPlugins || []),
                          ]}
                          rehypePlugins={[
                            ...(markdownSettings?.rehypePlugins || DEFAULT_REHYPE_PLUGINS),
                            ...(markdownSettings?.additionalRehypePlugins || []),
                          ]}
                          components={{
                            code(props) {
                              const {children, className, node, ...rest} = props
                              const { ref, style, ...syntaxProps} = rest;
                              const match = /language-(\w+)/.exec(className || '')
                              return match ? (
                                <SyntaxHighlighter
                                  {...syntaxProps}
                                  PreTag="div"
                                  children={String(children).replace(/\n$/, '')}
                                  language={match[1]}
                                />
                              ) : (
                                <code {...rest} className={className}>
                                  {children}
                                </code>
                              )
                            },
                            ...(markdownSettings?.components || {})
                          }}
                        />
                    : <ImageMessage src={message.content || ''} alt=""/>
                  }
                </MessageBubble>
              ))}
              <div ref={endOfMessages} />
            </ChatContainer>
          </ChatWindow>
          <FullWidthBar>
            <Grid container direction="column">
              <Stack direction="row">
                <IconButton component="label" >
                  <AttachFileIcon />
                  <Input
                    type="file"
                    sx={{ display: 'none' }}
                    inputProps={{ multiple: true, accept: 'text/*,application/rtf,application/json,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document' }} 
                    onChange={(e) => onSelectFile(e.target as HTMLInputElement)}
                  />
                </IconButton>
                {enableImages && 
                  <IconButton component="label" >
                    <ImageOutlinedIcon />
                    <Input
                      type="file"
                      sx={{ display: 'none' }}
                      inputProps={{ multiple: true, accept: 'image/*' }} 
                      onChange={(e) => onSelectImage(e.target as HTMLInputElement)}
                    />
                  </IconButton>
                }
              </Stack>
              <InputBase
                multiline
                sx={{ ml: 1, flex: 1 }}
                placeholder="Enter message here..."
                value={message}
                onChange={(e) => setMessage((e.target as HTMLInputElement).value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && !e.shiftKey) {
                    e.preventDefault();
                    onEnter();
                  }
                }}
              />
              <Stack direction="row">
                {attachments &&
                  attachments.map((attachment, i) => (
                    <Chip key={i} icon={<AttachFileIcon />} label={attachment.name} onDelete={() => onRemoveFile(i)} />
                  ))
                }
              </Stack>
              <Stack direction="row">
                {images &&
                  images.map((image, i) => (
                    <ImageContainer key={i}>
                      <ImagePreview
                        src={URL.createObjectURL(image)}
                        alt={image.name}
                      />
                      <IconButton color="error" sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => onRemoveImage(i)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </ImageContainer>
                  ))
                }
              </Stack>
            </Grid>
            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={onEnter}>
              <SendIcon />
            </IconButton>
          </FullWidthBar>
        </Container>
      )}
    </>
  );
};

export default Chat;
