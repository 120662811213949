import { useMutation } from 'react-query';

import Api from '../../api/api';

import { RolesDTO } from './useGetAllRoles';

export type UserCreateDTO = {
  username: string;
  email: string;
  password: string;
  roles: RolesDTO[];
};

export const createUser = async (params: UserCreateDTO) => {
  const [response, bodyOrError] = await Api.post('users/register', params);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useCreateUser = () => useMutation('createUser', createUser);
