import { useState } from "react";

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Drawer, Grid, IconButton, Toolbar, styled } from "@mui/material";

import { useMedia } from "../../../lib/media";
import { ChatCompletionSettings } from "../../../types/ChatCompletionSettings";

import Chat from "./Chat";
import ChatSettings from "./ChatSettings";

const ChatPanel = styled('div')({
  flexGrow: 1,
  position: 'relative',
});

const ChatSettingsPanel = styled(Drawer)<{
  open?: boolean;
}>(({ theme, open }) => ({
  height: '100%',
  maxWidth: '90%',
  position: 'relative',
  overflow: 'hidden',
  transition: (open ?
    theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })
    : theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })
  ),

  '.MuiDrawer-paper': {
    width: '400px',
    maxWidth: '90%',
    boxSizing: 'border-box',
    zIndex: 0,
    top: '0',
    height: 'auto',
    bottom: 0
  },
}));

const SettingsScrollWrapper = styled('div')({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
});

const StyledSettingsButton = styled(IconButton)({
  position: 'absolute',
  top: '5px',
  right: '5px',
});

const ChatShell = () => {
  const mediaBreakpoint = useMedia();
  const [showSettings, setShowSettings] = useState(false);
  const [model, setModel] = useState('OpenAI: gpt-4o-mini');
  const [settings, setSettings] = useState<ChatCompletionSettings>({
    temperature: 1,
    top_k: 1,
    top_p: 1,
    max_tokens: 1000,
    n: 1,
    presence_penalty: 0,
    frequency_penalty: 0,
    stream: true,
  });
  
  return (
    <Grid container direction="row" flexGrow={1}>
      <ChatPanel>
        <Chat model={model} enableImages={true} {...settings} />
        <StyledSettingsButton onClick={() => setShowSettings(!showSettings)} >
          <SettingsOutlinedIcon />
        </StyledSettingsButton>
      </ChatPanel>
      <ChatSettingsPanel
        open={showSettings}
        onClose={() => setShowSettings(false)}
        variant={mediaBreakpoint.sm ? 'temporary' : 'persistent'}
        anchor="right"
        sx={mediaBreakpoint.sm ? {} : { width: showSettings ? '400px' : 0 }}
      >
        { !mediaBreakpoint.sm && <Toolbar /> }
        <SettingsScrollWrapper>
          <ChatSettings model={model} settings={settings} setModel={setModel} setSettings={setSettings}/>
        </SettingsScrollWrapper>
      </ChatSettingsPanel>
    </Grid>
  );
};

export default ChatShell;