import { useState } from 'react';

import { useFormik } from 'formik';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, styled } from '@mui/material';

import { SubmitButton } from '../buttons';

const StyledDialogActions = styled(DialogActions)({
  padding: '1rem',
});

type ConfirmDialogProps = {
  title: string;
  description: string;
  trigger: (handleOpen: () => void) => JSX.Element;
  onConfirm: (handleClose: () => void, ...args: any) => {};
};

export const ConfirmDialog = ({ title, description, trigger, onConfirm }: ConfirmDialogProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formik = useFormik({
    initialValues: {},
    onSubmit: (...args) => {
      onConfirm(handleClose, ...args);
    },
  });

  return (
    <>
      {trigger(handleOpen)}
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          </DialogContent>
          <StyledDialogActions>
            <Button disabled={formik.isSubmitting} onClick={handleClose} color="secondary">
              Disagree
            </Button>
            <SubmitButton loading={formik.isSubmitting} autoFocus variant="contained" type="submit">
              Agree
            </SubmitButton>
          </StyledDialogActions>
        </form>
      </Dialog>
    </>
  );
};
