import { useCallback, useContext, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { Model } from 'openai/resources';

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { AlertSnackbarContext } from '../../../components/snackbars';
import useOpenAI from '../../../lib/chat/useOpenAI';
import { ChatCompletionSettings } from '../../../types/ChatCompletionSettings';
import { ChatControlConfiguration } from '../../../types/ChatControlConfiguration';

import ChatSettingControl from './ChatSettingControl';

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#fff',
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  maxWidth: '400px',
});

const supportedProviderSettings: Record<string, (keyof ChatCompletionSettings)[]> = {
  openai: [
    'temperature',
    'top_p',
    'max_tokens',
    'n',
    'frequency_penalty',
    'presence_penalty',
    'seed',
    'stream',
  ],
  gemini: [
    'temperature',
    'top_p',
    'top_k',
    'max_tokens',
    'n',
    'stream',
  ]
};

const controlConfigurations: { [key in keyof ChatCompletionSettings]: ChatControlConfiguration } = {
  temperature: {
    label: 'Temperature',
    type: 'number',
    step: 0.1,
    minValue: 0,
    maxValue: 2,
  },
  top_k: {
    label: 'Top K',
    type: 'number',
    minValue: 1,
  },
  top_p: {
    label: 'Top P',
    type: 'number',
    step: 0.1,
    minValue: 0,
    maxValue: 1,
  },
  max_tokens: {
    label: 'Max Tokens',
    type: 'number',
    minValue: 1,
  },
  n: {
    label: 'N',
    type: 'number',
    minValue: 1,
  },
  frequency_penalty: {
    label: 'Frequency Penalty',
    type: 'number',
    step: 0.1,
    minValue: -2,
    maxValue: 2,
  },
  presence_penalty: {
    label: 'Presence Penalty',
    type: 'number',
    step: 0.1,
    minValue: -2,
    maxValue: 2,
  },
  seed: {
    label: 'Seed',
    type: 'number',
    optional: true
  },
  stream: {
    label: 'Stream',
    type: 'flag',
  },
}

interface ChatSettingsProps {
  settings: ChatCompletionSettings;
  model: string;
  setSettings(settings: ChatCompletionSettings): void;
  setModel(model: string): void;
}

const ChatSettings: React.FC<ChatSettingsProps> = (props) => {
  const { settings, model, setSettings, setModel } = props;
  const provider = model.includes(':') ? model.split(':')[0] : 'OpenAI';
  const supportedSettings = supportedProviderSettings[provider.toLowerCase()] || [];
  const [modelsList, setModelsList] = useState<Model[]>([]);
  const [, setAlertSnackbar] = useContext(AlertSnackbarContext);

  const openai = useOpenAI();

  const fetchModelList = useCallback(async () => {
    try {
      const list = await openai.models.list();
      setModelsList(list.data);
    } catch (e) {
      setAlertSnackbar({
        message: `${e}`,
        severity: 'error',
      });
    }
  }, [openai, setAlertSnackbar]);

  useEffect(() => {
    if (modelsList.length === 0) fetchModelList();
  }, [fetchModelList, modelsList]);

  useEffect(() => {
    if (modelsList.length && !modelsList.some((m) => m.id === model)) {
      setModel(modelsList[0].id);
    }
  }, [model, setModel, modelsList]);

  const handleModelChange = (event: SelectChangeEvent) => {
    setModel(event.target.value);
  };

  const handleSettingChange = (setting: string, value?: number | boolean) => {
    setSettings({
      ...settings,
      [setting]: value,
    });
  };

  return (
    <Container>
      <FormControl sx={{ minWidth: '250px' }}>
        <InputLabel id="select-model" variant="standard" />
        <Select labelId="select-model" value={model} onChange={handleModelChange}>
          {modelsList.map((model) => (
            <MenuItem key={model.id} value={model.id}>
              {model.id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {
        supportedSettings.map((setting) => (
          <ChatSettingControl
            key={setting}
            config={controlConfigurations[setting]}
            name={setting}
            value={settings[setting]}
            onChange={(value) => handleSettingChange(setting, value)} />
        ))
      }
    </Container>
  );
};
  
export default ChatSettings;