import Select, { StylesConfig } from 'react-select';

import { Color } from '../../constants';

const selectStyles: StylesConfig = {
  valueContainer: (styles) => ({
    ...styles,
    height: '55px',
    fontFamily: 'Roboto',
  }),
  control: (styles, state: any) => ({
    ...styles,
    height: '55px',
    fontFamily: 'Roboto',
    '&:hover': {
      borderColor: state.isFocused ? styles.borderColor : Color.black,
    },
    borderColor: state.isFocused ? styles.borderColor : state.selectProps.required ? styles.borderColor : Color.red,
  }),
  menu: (styles) => ({
    ...styles,
    fontFamily: 'Roboto',
  }),
};

export const SelectField = (props: any) => {
  const { defaultValue, name, options, onChange, placeholder, changeOptionLabel, changeOptionValue, required } = props;

  return (
    <Select
      isMulti
      required={required}
      styles={selectStyles}
      defaultValue={defaultValue}
      name={name}
      options={options}
      onChange={onChange}
      isSearchable={false}
      placeholder={placeholder}
      theme={(themes) => ({
        ...themes,
        colors: {
          ...themes.colors,
          primary: Color.primary,
          danger: Color.destructiveColor,
        },
      })}
      getOptionLabel={(option) => option[changeOptionLabel]}
      getOptionValue={(option) => option[changeOptionValue]}
    />
  );
};
