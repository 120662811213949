import { useQuery } from 'react-query';

import Api from '../../api/api';

export const getUser = async (userId: string) => {
  const [response, bodyOrError] = await Api.get(`users/${userId}`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useGetUser = (userId: string) =>
  useQuery(['getUser', userId], () => getUser(userId), {
    enabled: userId !== 'new' || !!userId
  });
